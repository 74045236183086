import FooterPage from "../FooterPage/footerPage";
import NavbarPage from "../NabarPage/navbarPage";
import {useEffect, useState} from "react";
import {TailSpin} from "react-loader-spinner";

const ContactUs = () => {

    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleOnSubmitForm = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('contact-form');
        // @ts-ignore
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                alert("Success!");
                setLoadingButton(false);
                form.reset();
            }).catch((error) => {
            alert("Failure!");
            setLoadingButton(false);
            form.reset();
            console.log(error)
        })
    }


    return (
        <div className="container-fluid">
            <div className="row bg-contact-us-container">
                <div className="col-12">
                    {/*nav bar*/}
                    <div className="row justify-content-center fixed-top pb-4 ">
                        <NavbarPage/>
                        <div className="col-12 p-0 ">
                            {/*<nav className="row navbar navbar-danger bg-color-navbar d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="col-4 font-color-white d-flex justify-content-center align-items-center d-block d-md-none">*/}
                            {/*        <a href="/index.html">*/}
                            {/*            <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6 d-flex flex-row justify-content-between align-content-center d-none d-md-flex px-4 ">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-3">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-7 d-flex flex-row justify-content-start align-items-center px-4 gap-4">*/}
                            {/*                <div className="nav-item dropdown">*/}
                            {/*                    <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                        services*/}
                            {/*                    </a>*/}
                            {/*                    <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdown">*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/about.html" className=" btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/contact-us.html" className="btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        Contact us*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="col-6 d-flex justify-content-end align-items-center d-none d-md-flex">*/}
                            {/*        <a href="/book-appointment.html">*/}
                            {/*            <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                            {/*                Book an appointment*/}
                            {/*            </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2">*/}
                            {/*        <button className="navbar-toggler bg-transparent border-none  d-block d-md-none" type="button"*/}
                            {/*                data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent"*/}
                            {/*                aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                aria-label="Toggle navigation">*/}
                            {/*            <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</nav>*/}
                            {/*<div className="collapse" id="navbarToggleExternalContent">*/}
                            {/*    <div className="bg-color-navbar p-4 d-flex flex-column justify-content-between align-content-start gap-2 ">*/}

                            {/*        <div className="">*/}
                            {/*            <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                services*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdownMobile">*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="">*/}
                            {/*            <a href="/about.html" className=" font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                <label> About</label>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="">*/}
                            {/*            <a href="/contact-us.html" className=" font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                <label>Contact us</label>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className=" border-2">*/}
                            {/*            <a href="/book-appointment.html">*/}
                            {/*                <button type="button" className="button-bg font-family-raleway text-white font-size-14 font-weight-500p border-none">*/}
                            {/*                    Book an appointment*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 pt-5"}></div>
                    </div>

                    <div className="row py-4">
                        <div className="col-10 pt-4 mt-4 col-md-10">
                            <div className="row bg-white">
                                <div className="col-12 col-md-4 p-0 d-flex">
                                    <div className="rounded-end-circle write-us-container h-100">
                                        <label
                                            className="ps-md-5 ms-md-5 font-size-88 font-weight-600p font-color-basic-black">Write
                                            To Us</label>
                                        <div className="pe-sm-2 pe-md-4">
                                            <img className="w-100 rounded-end-circle"
                                                 src="/images/contact-us/bg-square-pots-imge.png" alt="pots-image"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-1 d-none d-md-block"></div>
                                <div className="col-12 col-md-6 py-4">
                                    <form className="font-family-raleway" id="contact-form" method="POST"
                                          onSubmit={handleOnSubmitForm}
                                          action="https://script.google.com/macros/s/AKfycbyTVS4Q7mN7XSgy1bnL83b6Kgc9bY4IqivuytA0XQ13s9IujeFPQR-yFBOoI1_JwjhS/exec">
                                        <div className="form-group pb-4">
                                            <label htmlFor="exampleInputName"
                                                   className="font-color-grey-dark font-weight-500p font-size-18 pb-2">Your
                                                Full Name</label>
                                            <input type="text" required="required" name="FullName"
                                                   className="form-control bg-color-container" id="exampleInputName"
                                                   aria-describedby="fullName" placeholder="Enter your name"/>
                                        </div>
                                        <div className="form-group pb-4">
                                            <label htmlFor="exampleInputEmail1"
                                                   className="font-color-grey-dark font-weight-500p font-size-18 pb-2">Email
                                                Address</label>
                                            <input type="email" required="required" name="Email"
                                                   className="form-control bg-color-container" id="exampleInputEmail1"
                                                   aria-describedby="emailHelp" placeholder="Enter email"/>
                                        </div>
                                        <div className="form-row d-block d-md-flex justify-content-between pb-4">
                                            <div className="form-group col-12 col-md-6">
                                                <label htmlFor="phoneNumber"
                                                       className="font-color-grey-dark font-weight-500p font-size-18 pb-2">Mobile
                                                    Number</label>
                                                <input type="number" required="required" name="PhoneNumber"
                                                       className="form-control bg-color-container" id="phoneNumber"
                                                       placeholder="Enter mobile number"/>
                                            </div>
                                            <div className="form-group col-12 col-md-5">
                                                <label htmlFor="location"
                                                       className="font-color-grey-dark font-weight-500p font-size-18 pb-2">Location</label>
                                                <select id="location" required="required" name="Location"
                                                        className="form-control bg-color-container font-family-raleway w-100">
                                                    <option value="" selected disabled={true}>Select</option>
                                                    <option value="Madhapur">Madhapur</option>
                                                    <option value="Gachibowli" disabled={true}>Gachibowli (coming
                                                        soon)
                                                    </option>
                                                    <option value="Ameerpet" disabled={true}>Ameerpet (coming soon)
                                                    </option>
                                                    <option value="Nagole" disabled={true}>Nagole (coming soon)</option>
                                                    <option value="Raidurg" disabled={true}>Raidurg (coming soon)
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group pb-4">
                                            <label htmlFor="additionalComment"
                                                   className="font-color-grey-dark font-weight-500p font-size-18 pb-2">Additional
                                                Comment</label>
                                            <textarea rows="5" name="AdditionalComments"
                                                      className="form-control bg-color-container" id="additionalComment"
                                                      placeholder="write here..." aria-label="With textarea"></textarea>
                                        </div>
                                        <button type="submit"
                                                className=" button-bg font-size-14 font-weight-500p text-white font-family-raleway">
                                            {!loadingButton ? "Submit" :
                                                <TailSpin height={20} width={20} strokeWidth={3} color={'#FFFFFF'}/>}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row py-4 justify-content-end">
                        <div className="col-10 col-md-9 bg-white location-contact-us-container">
                            <div className="row justify-content-between p-4">
                                <div className="col-12 col-md-5">
                                    <div>
                                        <label className="font-size-42 font-weight-500p font-color-neutral-black">Contact
                                            us</label>
                                    </div>
                                    <div className="mt-4 d-flex gap-2">
                                        <div>
                                            <i data-feather="phone" className="font-color-gold"></i>
                                        </div>
                                        <div className="d-flex flex-column font-family-raleway">
                                            <label
                                                className="font-size-18 font-weight-500p font-color-gold">Phone</label>
                                            <label className="font-size-18 font-weight-400p">040-45026989</label>
                                        </div>
                                    </div>
                                    <div className="mt-4 d-flex gap-2">
                                        <div>
                                            <i data-feather="mail" className="font-color-gold"></i>
                                        </div>
                                        <div className="d-flex flex-column font-family-raleway">
                                            <label
                                                className="font-size-18 font-weight-500p font-color-gold">Email</label>
                                            <div>
                                                <a href={"mailto:operations@thesuitssalonspa.com"}
                                                   className="font-size-18 font-color-neutral-black font-weight-400p"
                                                   style={{textDecorationLine: "none"}}>
                                                    operations@thesuitssalonspa.com
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 d-flex gap-2">
                                        <div className="">
                                            <i data-feather="map-pin" className="font-color-gold"></i>
                                        </div>
                                        <div className="d-flex flex-column font-family-raleway">
                                            <label
                                                className="font-size-18 font-weight-500p font-color-gold">Location</label>
                                            <label className="font-size-18 font-weight-400p">
                                                1st Floor And 2nd Floor,
                                                Plot No: 4 & 5, behind Image Hospital,
                                                Arunodaya Colony, Vittal Rao Nagar,
                                                Madhapur, Telangana - 500081
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={"w-100 h-100"}>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.325300576363!2d78.38298097377154!3d17.444137601170592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915f63228fcf%3A0x383cb11e6e4aef06!2sImage%20Hospital%20Rd%2C%20Arunodaya%20Colony%2C%20Vittal%20Rao%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1719293263154!5m2!1sen!2sin"
                                            width="100%" height="100%" style={{border: 0}} allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    {/*<img className="img-fluid h-100 w-100" src="/images/contact-us/salon-map-location-image.png" alt="salon location"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*footer section*/}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
