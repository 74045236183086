import logo from './logo.svg';
import './App.css';
import LandingPage from "./components/LandingPage/landingPage";
import 'bootstrap/dist/css/bootstrap.css'
import "./components/Styles/scss/main.scss"
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AboutUs from "./components/AboutUs/aboutUs";
import ContactUs from "./components/ContactUs/contactUs";
import BookAppointment from "./components/BookAnAppointment/bookAppointment";
import AllServices from "./components/AllServicesPage/allServices";
import ServiceDetails from "./components/ServiceDetailsPage/serviceDetails";
import Gallery from "./components/Gallery/gallery";

function App() {
  return (
    <div className="">
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<LandingPage />} />
                <Route path={"/about"} element={<AboutUs />} />
                <Route path={"/contactus"} element={<ContactUs />} />
                <Route path={"/book-an-appointment"} element={<BookAppointment />} />
                <Route path={"/all-services"} element={<AllServices />} />
                <Route path={"/service-details/:id"} element={<ServiceDetails />} />
                <Route path={'/gallery'} element={<Gallery />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
