import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";
import {useEffect} from "react";

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return(
        <div className="container-fluid">

            {/*hero section*/}
            <div className="row bg-color-container">
                <div className="col-12">

                    {/*nav bar*/}
                    <div className="row justify-content-center pb-4 ">
                        <NavbarPage />
                        <div className="col-12 p-0 ">
                            {/*<nav className="row navbar navbar-danger bg-color-navbar d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="col-4 font-color-white d-flex justify-content-center align-items-center d-block d-md-none">*/}
                            {/*        <a href="/index.html">*/}
                            {/*            <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo">*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6 d-flex flex-row justify-content-between align-content-center d-none d-md-flex px-4 ">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-3">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo">*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-7 d-flex flex-row justify-content-start align-items-center px-4 gap-4">*/}
                            {/*                <div className="nav-item dropdown">*/}
                            {/*                    <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                        services*/}
                            {/*                    </a>*/}
                            {/*                    <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdown">*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/about.html" className=" btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/contact-us.html" className="btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        Contact us*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="col-6 d-flex justify-content-end align-items-center d-none d-md-flex">*/}
                            {/*        <a href="/book-appointment.html">*/}
                            {/*            <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                            {/*                Book an appointment*/}
                            {/*            </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2">*/}
                            {/*        <button className="navbar-toggler bg-transparent border-none  d-block d-md-none" type="button"*/}
                            {/*                data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent"*/}
                            {/*                aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                aria-label="Toggle navigation">*/}
                            {/*            <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</nav>*/}
                            {/*<div className="collapse mt-4" id="navbarToggleExternalContent">*/}
                            {/*    <div className="bg-color-navbar p-4 d-flex justify-content-between align-content-start gap-2 flex-column">*/}
                            {/*        <div className="">*/}
                            {/*            <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="/all-services-details.html" id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                services*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu bg-color-navbar font-family-raleway" aria-labelledby="navbarDropdownMobile">*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="">*/}
                            {/*            <a href="/about.html" className=" btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                About*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="">*/}
                            {/*            <a href="/contact-us.html" className="btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                Contact us*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className=" border-2">*/}
                            {/*            <a href="/book-appointment.html">*/}
                            {/*                <button type="button" className="button-bg font-family-raleway text-white font-size-14 font-weight-500p border-none">*/}
                            {/*                    Book an appointment*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*hero section*/}
                    <div className="row pt-4">
                        <div className="col-12 mt-4">
                            <div className="row pt-4">
                                <div className="col-12 col-md-4 justify-content-center p-0">
                                    <img className="img-fluid w-100" src="/images/about/about-hero-section-image.png" alt="hero section image"/>
                                </div>
                                <div className="col-12 col-md-1 py-2"></div>
                                <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                    <div>
                                        <label className="font-size-88 font-weight-600p font-color-gold">Welcome to Our Family.</label>
                                    </div>
                                    <div>
                                        <label className="font-size-18 font-color-grey-dark font-weight-500p font-family-raleway">
                                            Walk in- Normal, Walk out- fabulous.
                                            Here beauty exceeds expectations without exceeding the budget.
                                        </label>
                                    </div>
                                    <div className="py-4">
                                        <Link to={"/book-an-appointment"}>
                                            <button type="button" className="button-bg font-size-18 font-weight-500p font-family-raleway text-white">Book an appointment</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*services-section*/}
            <div className="row bg-color-container pb-4">
                <div className="col-12 p-0">
                    <div className="row">

                        <div className="col-10 col-md-4 bg-who-are-we-container h-100 p-0">
                            <div className="bg-who-are-we-card-container text-center w-100">
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4"></div>
                                <label className="font-size-42 font-weight-500p font-color-bg-light-pallete">Who Are We?</label>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4"></div>
                            </div>
                            <div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4"></div>
                                <div className="py-4"></div>
                            </div>
                        </div>
                        <div className="col-12 py-4 col-md-1"></div>
                        <div className="col-12 col-md-6 px-4 d-flex flex-column justify-content-center gap-4">
                            <div className="font-size-18 font-color-theme-600 font-weight-500p font-family-raleway">
                                <label>
                                    SUITS Salon’ opened its door with a simple dream: to make people beautiful and confident with the services. We started small with just a few chairs and a big dream of making it the best salon near you. At our salon we believe in enhancing your natural beauty with personalised care and premium products. Visit us and discover the perfect blend of style and sophistication.
                                </label>
                            </div>
                            <div className="font-size-18 font-color-theme-600 font-weight-500p font-family-raleway">
                                <label>
                                    At our salon, we believe in enhancing your natural beauty with personalised care. Each service is tailored to your needs, ensuring you leave feeling refreshed and confident. Our team of experts uses premium products to provide the best results.
                                </label>
                            </div>
                            <div className="font-size-18 font-color-theme-600 font-weight-500p font-family-raleway">
                                <label>
                                    Visit us and discover the perfect blend of style and sophistication. SUITS Salon is dedicated to making you look and feel your best. Experience our warm, welcoming atmosphere and let us help you achieve your beauty goals.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*values that defined us*/}
            <div className="row bg-color-container">
                <div className="col-12 p-0">
                    <div className="row">
                        <div className="col-12 col-md-8 order-1 order-md-0 mt-4 p-4">
                            <div className="row justify-content-center">
                                <div className="col-8 col-md-4 pt-4">
                                    <div className="bg-transparent card about-values-container text-center gap-4 p-4">
                                        <div>
                                            <label className="font-size-42 font-weight-500p font-color-light-pallete">01</label>
                                            <div>
                                                <label className="font-size-32 font-weight-400p font-color-gold">Innovation</label>
                                            </div>
                                        </div>
                                        <div className="px-4">
                                            <label className="font-size-18 font-weight-500p font-color-grey-dark font-family-raleway">We keep up with the latest trends and techniques in hair and beauty,
                                                and we offer you a variety of options to suit your needs and style.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 col-md-4 pt-4">
                                    <div className="my-4 py-4 d-none d-md-block"></div>
                                    <div className="card about-values-container text-center bg-transparent gap-4 p-4">
                                        <div className="d-flex flex-column text-center">
                                            <label className="font-size-42 font-weight-500p font-color-light-pallete">02</label>
                                            <div className="">
                                                <label className="font-size-32 font-weight-400p font-color-gold">Professionalism</label>
                                            </div>
                                        </div>
                                        <div className="px-4">
                                            <label className="font-size-18 font-weight-500p font-color-grey-dark font-family-raleway">
                                                We maintain high standards of professionalism and ethics in our salon,
                                                and we respect your privacy and preferences.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 col-md-4 pt-4">
                                    <div className="card about-values-container bg-transparent text-center gap-4 p-4">
                                        <div>
                                            <label className="font-size-42 font-weight-500p font-color-light-pallete">03</label>
                                            <div>
                                                <label className="font-size-32 font-weight-400p font-color-gold">Customer satisfaction</label>
                                            </div>
                                        </div>
                                        <div className="px-4">
                                            <label className="font-size-18 font-weight-500p font-color-grey-dark font-family-raleway">
                                                We strive to meet and exceed your expectations with our quality services and products.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 d-block d-md-none"></div>

                        <div className="col-10 col-md-4 bg-values-container h-100 p-0">
                            <div className="bg-values-card-container text-center px-2 w-100">
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4"></div>
                                <label className="font-size-42 font-weight-500p font-color-bg-light-pallete">The Values That Define Us</label>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4"></div>
                            </div>
                            <div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-4"></div>
                                <div className="py-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*our team of experts*/}
            <div className="row py-4 bg-color-container justify-content-center">
                <div className="col-12 p-md-0 col-md-11 mt-4">
                    <div className="d-block d-md-flex justify-content-around align-items-center gap-4 bg-white py-2 px-4">
                        <div className="p-2"></div>
                        <div>
                            <label className="font-size-42 font-color-grey-dark font-weight-500p">Our Team of Experts</label>
                        </div>
                        <div className="pt-4 d-block d-md-none"></div>
                        <div>
                            <label className="font-size-18 font-weight-500p font-color-theme-600 font-family-raleway">
                                The passionate and creative individuals who work at our salon are a perfect blend of excellence and professionalism.
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {/*team details container*/}
            <div className="row bg-color-container justify-content-center py-4">
                <div className="col-12 px-md-4">
                    <div className="row justify-content-center">
                        <div className="col-12 pt-2 col-md-4">
                            <div className="bg-white p-2">
                                <div>
                                    <img className="img-fluid w-100" src="/images/about/salon-team-member-image.png" alt="team member image"/>
                                </div>
                                <div className="gap-4 text-center pt-2">
                                    <label className="font-size-18 font-color-gold font-weight-500p font-family-raleway">Sayeesha</label>
                                    <div>
                                        <label className="font-size-32 font-color-grey-dark font-weight-400p">Hair stylist</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-2 col-md-4">
                            <div className="bg-white p-2">
                                <div>
                                    <img className="img-fluid w-100" src="/images/about/salon-team-member-image.png" alt="team member image"/>
                                </div>
                                <div className="gap-4 text-center pt-2">
                                    <label className="font-size-18 font-color-gold font-weight-500p font-family-raleway">Sayeesha</label>
                                    <div>
                                        <label className="font-size-32 font-color-grey-dark font-weight-400p">Hair stylist</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-2 col-md-4">
                            <div className="bg-white p-2">
                                <div>
                                    <img className="img-fluid w-100" src="/images/about/salon-team-member-image.png" alt="team member image"/>
                                </div>
                                <div className="gap-4 text-center pt-2">
                                    <label className="font-size-18 font-color-gold font-weight-500p font-family-raleway">Sayeesha</label>
                                    <div>
                                        <label className="font-size-32 font-color-grey-dark font-weight-400p">Hair stylist</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*footer section*/}
            <FooterPage />
        </div>
    )
}

export default AboutUs
