import React from "react";
import * as Icon from 'react-feather'
import {Link} from "react-router-dom";
import serviceData from '../Models/services.json'

const FooterPage = () => {
    return (
        <div className="row px-md-4 px-3 py-4 bg-footer-container">
            <div className="col-12 pt-4 px-0 font-family-raleway">
                <div className="row justify-content-around">
                    <div className="col-12 col-md-3 pt-4">
                        <div className="row d-flex flex-column gap-4">
                            <div className="col-md-6 col-4">
                                <Link to={"/"}>
                                    <img className="img-fluid" src="/images/landing/salon-footer-logo.png" alt="logo"  />
                                </Link>
                            </div>
                            <div className="col-12 pt-4 mt-4 d-none d-md-block"></div>
                            <div className="col-12">
                                <label className="font-size-14 font-color-theme-50 font-weight-400p">© 2024 Name. All rights reserved.</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="row justify-content-around">
                            <div className="col-12 col-md-3 pt-4">
                                <div className="d-flex flex-column gap-2">
                                    <label className="font-size-18 font-weight-500p font-color-theme-50">Company</label>
                                    <div className="d-flex flex-column gap-2">
                                        <div>
                                            <Link to={"/"}>
                                                <label className="font-size-14 font-color-theme-50 services-button font-weight-500p">Home</label>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={"/about"}>
                                                <label className="font-size-14 font-color-theme-50 services-button font-weight-500p">About</label>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={"/contactus"}>
                                                <label className="font-size-14 font-color-theme-50 services-button font-weight-500p">Contact us</label>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={"/gallery"}>
                                                <label className="font-size-14 font-color-theme-50 services-button font-weight-500p">Gallery</label>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pt-4">
                                <div className="d-flex flex-column gap-2">
                                    <label className="font-size-18 font-weight-500p font-color-theme-50">Services</label>
                                    <div className="d-flex flex-column gap-2">
                                        {serviceData.map(eachService => (
                                            <div key={eachService.id}>
                                                <Link to={`/service-details/${eachService.service_slug}`}>
                                                    <label className="font-size-14 font-color-theme-50 services-button font-weight-500p">{eachService.service_name}</label>
                                                </Link>
                                            </div>
                                        ) )}
                                        {/*<div>*/}
                                        {/*    <Link to={"/service-details"}>*/}
                                        {/*        <label className="font-size-14 font-color-theme-50 font-weight-500p">Spa</label>*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <Link to={"/service-details"}>*/}
                                        {/*        <label className="font-size-14 font-color-theme-50 font-weight-500p">Nail</label>*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <Link to={"/service-details"}>*/}
                                        {/*        <label className="font-size-14 font-color-theme-50 font-weight-500p">Make Up</label>*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <Link to={"/service-details"}>*/}
                                        {/*        <label className="font-size-14 font-color-theme-50 font-weight-500p">Skin Care</label>*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <Link to={"/service-details"}>*/}
                                        {/*        <label className="font-size-14 font-color-theme-50 font-weight-500p">Waxing</label>*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 pt-4">
                        <div className="d-flex flex-column gap-2">
                            <label className="font-size-18 font-color-theme-50 font-weight-500p">Contact</label>
                            <div className="d-flex gap-2">
                                <div>
                                    <Icon.Phone className="text-white"/>
                                </div>
                                <div>
                                    <label className="font-size-14 font-weight-500p font-color-theme-50">040-45026989</label>
                                </div>
                            </div>
                            <div className="d-flex gap-2">
                                <div>
                                    <Icon.Mail className="text-white"/>
                                </div>
                                <div>
                                    <a href="mailto:operations@thesuitssalonspa.com" className="font-size-14 font-color-theme-50 font-weight-500p" style={{textDecorationLine: "none"}}>
                                        operations@thesuitssalonspa.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-8 col-md-12 pt-4  ms-md-0">
                <div className="row justify-content-end">
                    <div className="col-12 col-md-2 footer-logo-container">
                        <div className="py-2">
                            <label className="font-size-14 font-weight-500p font-color-gold">
                                Designed by: <a href={"https://7dotss.com/"} target={"_blank"}><img className="img-fluid" src="/images/landing/7-dots-logo.png" alt="7 dots logo"/></a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterPage
