import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from "react-router-dom";
import serviceData from '../Models/services.json'

const NavbarPage = () => {

    return(
        <div className={'col-12 p-0'}>
            <Navbar collapseOnSelect expand="md" className="bg-color-navbar justify-content-center" fixed={"top"}>
                <Container className='row'>
                    <Navbar.Brand className="col-md-2 col-4">
                        <div className="row">
                            <div className="col-10 font-color-white d-flex justify-content-center align-items-center ">
                                <Link to={"/"}>
                                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>
                                </Link>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className={"col-2 border-0"} />

                    <Navbar.Collapse id="" className={"col-md-6 col-12"}>
                        <Nav className="d-flex gap-md-5 gap-2 align-items-md-center">
                            <NavDropdown title="Services" className="bg-color-navbar font-size-14 font-weight-500p font-family-raleway">
                                {serviceData.map(eachService => (

                                    <NavDropdown.Item className="font-size-14 font-weight-500p font-color-grey-dark" key={eachService.id}>
                                        <Link to={`/service-details/${eachService.service_slug}`} className={"text-decoration-none font-size-14 font-weight-500p font-color-grey-dark"}>
                                            {eachService.service_name}
                                        </Link>
                                    </NavDropdown.Item>
                                ) )}
                                {/*<NavDropdown.Item className="font-size-14 font-weight-500p font-color-grey-dark">Spa</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item className="font-size-14 font-weight-500p font-color-grey-dark">Nail</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item className="font-size-14 font-weight-500p font-color-grey-dark">Make Up</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item className="font-size-14 font-weight-500p font-color-grey-dark">Skin Care</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item className="font-size-14 font-weight-500p font-color-grey-dark">Waxing</NavDropdown.Item>*/}
                            </NavDropdown>
                            <Nav>
                                <div>
                                    <Link to={"/about"}>
                                        <button type={"button"} className=" px-0 border-0 bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">
                                            About
                                        </button>
                                    </Link>
                                </div>
                            </Nav>
                            <Nav>
                                <div>
                                    <Link to={"/contactus"}>
                                        <button className="px-0 border-0 bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">
                                            Contact us
                                        </button>
                                    </Link>
                                </div>
                            </Nav>
                            <Nav>
                                <div>
                                    <Link to={"/gallery"}>
                                        <button className="px-0 border-0 bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">
                                            Gallery
                                        </button>
                                    </Link>
                                </div>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Collapse className={"col-md-4"}>
                        <Nav className="d-flex align-items-md-center py-3 py-md-0 w-100 justify-content-md-end">
                            <Nav>
                                <div>
                                    <Link to={"/book-an-appointment"}>
                                        <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">
                                            Book an appointment
                                        </button>
                                    </Link>
                                </div>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export  default NavbarPage
