import React, {useEffect} from "react";
import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import * as Icon from 'react-feather'

const Gallery = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-beige-dark"}>
                <div className={"col-12"}>
                    {/*  nav bar  */}
                    <div className={'row pb-4'}>
                        <NavbarPage/>
                    </div>
                    <div className={"row"}>
                        <div className={'col-12 pt-4 pt-md-5 mt-md-1'}></div>
                    </div>
                    {/*  hero section   */}
                    <div className={"row bg-beige-dark justify-content-center"}>
                        <div className={'col-12 py-5 my-md-2'}>
                            <div className={'w-100 d-flex flex-column align-items-center font-family-silk-serif'}>
                                <div>
                                    <label className={"font-size-32 font-weight-400p font-color-grey-dark"}>The Perfect
                                        Look</label>
                                </div>
                                <div>
                                    <label className={"font-size-88 font-weight-600p font-color-neutral-black"}>Our
                                        Gallery</label>
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 px-0 pb-5 mb-2 d-flex justify-content-around"}>
                            <div className={"row gap-3 gap-md-0 justify-content-around"}>
                                <div className={'col-12 col-md-4 d-flex flex-column align-items-md-start align-items-center'}>
                                    <img src={'/images/gallery/hero-image-with-plants.png'} alt={'hero-image-with-plants'}/>
                                    <div className={"hero-section-reception-image"}>
                                        <img src={'/images/gallery/hero-image-with-entrance-view.png'} alt={'hero-image-with-entrance-view'}/>
                                    </div>
                                </div>
                                <div className={'col-12 col-md-4 d-flex flex-column align-items-center'}>
                                    <img src={'/images/gallery/hero-reception-image.png'} alt={'hero-reception-image'}/>
                                    <div className={"hero-section-light-image"}>
                                        <img src={'/images/gallery/hero-light-image.png'} alt={'hero-light-image'}/>
                                    </div>
                                </div>
                                <div className={'col-12 col-md-4 d-flex flex-column align-items-center'}>
                                    <img src={'/images/gallery/hero-chairs-image.png'} alt={'hero-chairs-image'}/>
                                    <div className={"hero-section-make-up-area-image"}>
                                        <img src={'/images/gallery/hero-makeup-area-image.png'} alt={'hero-makeup-area-image'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  gallery section  */}
                    <div className={"row justify-content-center bg-white"} id={'gallery-section'}>
                        <div className={"col-11 py-5 my-2"}>
                            <div className={"row gap-3 gap-md-0"}>
                                <div className={"col-md-4 col-12 p-0"}>
                                    <div className={'w-100'}>
                                        <h3 className={"font-size-88 font-weight-600p font-color-theme-950 font-family-silk-serif"}>Take a Virtual Tour</h3>
                                        <div>
                                            <label className={"font-size-18 font-weight-500p font-color-grey-dark font-family-raleway"}>
                                                We'll style while you smile and relax in a cosy interior. Here’s a small virtual tour of our salon. Visit us to get the royal treatment in a royal environment.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"row mt-md-5 mt-3 d-flex flex-column gap-3"}>
                                        <div className={"col-12"}>
                                            <img className={"w-100"} src={"/images/gallery/salon-gallery-image-8.png"} alt={"salon-gallery-image-8"}/>
                                        </div>
                                        <div className={"col-12"}>
                                            <img className={"w-100"} src={"/images/gallery/salon-gallery-322-image-1.png"} alt={"salon-gallery-322-image-1"}/>
                                        </div>
                                        <div className={"col-12"}>
                                            <img className={'w-100'} src={"/images/gallery/salon-gallery-image-5.png"} alt={"salon-gallery-image-5"}/>
                                        </div>
                                        <div className={"col-12"}>
                                            <img className={"w-100"} src={"/images/gallery/salon-gallery-322-image-5.png"} alt={"salon-gallery-322-image-1"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-4 col-12 px-md-3 p-0"}>
                                    <div className={'row d-flex flex-column gap-3'}>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-image-4.png"} alt={"salon-gallery-image-4"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-322-image-2.png"} alt={"salon-gallery-322-image-2"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-image-9.png"} alt={"salon-gallery-image-9"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-322-image-4.png"} alt={"salon-gallery-322-image-4"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-image-1.png"} alt={"salon-gallery-image-9"}/>
                                    </div>
                                </div>
                                <div className={"col-md-4 col-12 p-0"}>
                                    <div className={'row d-flex flex-column gap-3'}>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-322-image-10.png"} alt={"salon-gallery-322-image-10"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-image-7.png"} alt={"salon-gallery-image-7"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-322-image-7.png"} alt={"salon-gallery-322-image-2"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-image-2.png"} alt={"salon-gallery-image-2"}/>
                                        <img className={"w-100 col-12"} src={"/images/gallery/salon-gallery-322-image-6.png"} alt={"salon-gallery-322-image-2"}/>
                                    </div>
                                </div>
                                <div className={"col-12 d-flex justify-content-end pt-4"}>
                                    <a href={'#gallery-section'}>
                                        <button type={'button'} className={'button-reviews-left-right-arrow d-flex align-items-center p-3 rounded-circle border-0'}>
                                           <Icon.ArrowUp className={'text-white'} />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  footer page  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default Gallery;
