import NavbarPage from "../NabarPage/navbarPage";
import React, {useEffect} from "react";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";
import servicesData from '../Models/services.json'
const AllServices = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return(
        <div className="container-fluid">
            {/*services section*/}
            <div className="row bg-color-container pb-4">
                <div className="col-12">

                    {/*nav bar*/}
                    <div className="row justify-content-center pb-4 ">
                        <NavbarPage />
                        <div className="col-12 p-0 ">
                            {/*<nav className="row navbar navbar-danger bg-color-navbar d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="col-4 font-color-white d-flex justify-content-center align-items-center d-block d-md-none">*/}
                            {/*        <a href="/index.html">*/}
                            {/*            <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6 d-flex flex-row justify-content-between align-content-center d-none d-md-flex px-4 ">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-3">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-7 d-flex flex-row justify-content-start align-items-center px-4 gap-4">*/}
                            {/*                <div className="nav-item dropdown">*/}
                            {/*                    <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                        services*/}
                            {/*                    </a>*/}
                            {/*                    <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdown">*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/about.html" className=" btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/contact-us.html" className="btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        Contact us*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            
                            {/*    <div className="col-6 d-flex justify-content-end align-items-center d-none d-md-flex">*/}
                            {/*        <a href="/book-appointment.html">*/}
                            {/*            <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                            {/*                Book an appointment*/}
                            {/*            </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2">*/}
                            {/*        <button className="navbar-toggler bg-transparent border-none  d-block d-md-none" type="button"*/}
                            {/*                data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent"*/}
                            {/*                aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                aria-label="Toggle navigation">*/}
                            {/*            <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</nav>*/}
                            {/*<div className="collapse mt-2" id="navbarToggleExternalContent">*/}
                            {/*    <div className="bg-color-navbar p-4 d-flex justify-content-between align-content-start gap-2 flex-column">*/}
                            {/*        <div className="">*/}
                            {/*            <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="/all-services-details.html" id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                services*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu bg-color-navbar font-family-raleway" aria-labelledby="navbarDropdownMobile">*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            
                            {/*        <div className="">*/}
                            {/*            <a href="/about.html" className=" btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                About*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="">*/}
                            {/*            <a href="/contact-us.html" className="btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                Contact us*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className=" border-2">*/}
                            {/*            <a href="/book-appointment.html">*/}
                            {/*                <button type="button" className="button-bg font-family-raleway text-white font-size-14 font-weight-500p border-none">*/}
                            {/*                    Book an appointment*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="row pt-2">
                        <div className="col-12 text-center mt-4 pt-4">
                            <label className="font-color-gold font-size-42 font-weight-500p">Services We Offer</label>
                        </div>
                    </div>
                    <div className="row pt-4 px-4">
                        <div className="col-12 px-4">
                            <div className="row justify-content-center px-4">
                                {servicesData.map(each => (
                                    <div className="col-12 col-md-4 mt-4 pt-2" key={each.id}>
                                        <div className="service-container card bg-transparent border-0">
                                            <div className="img-container">
                                                <img className="img-fluid w-100 rounded-top-circle"  src={each.service_image} alt={each.alt}/>
                                            </div>
                                            <div className="bg-services-card-container text-center p-4">
                                                <label className="font-size-32 font-color-brown font-weight-500p">{each.service_name}</label>
                                                <div className="pt-md-2 pt-sm-1">
                                                    <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">
                                                        {each.service_description}
                                                    </label>
                                                </div>
                                                <div className="pt-4">
                                                    <Link to={`/service-details/${each.service_slug}`}>
                                                        <button type="button" className="button-see-details font-family-raleway">
                                                            See details
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-12 d-none col-md-4 mt-4">
                                    <div className="service-container card bg-transparent border-0">
                                        <div className="img-container">
                                            <img className="img-fluid w-100 rounded-top-circle" src="/images/landing/hair-services-image.png" alt="hair services image"/>
                                        </div>
                                        <div className="bg-services-card-container text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">Hair Service</label>
                                            <div className="pt-md-2 pt-sm-1">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,
                                                    we take pride in creating styles for any occasion!
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={"/service-details"}>
                                                    <button type="button" className="button-see-details font-family-raleway">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-none col-md-4 mt-4">
                                    <div className="service-container card bg-transparent border-0">
                                        <div className="img-container">
                                            <img className="img-fluid w-100 rounded-top-circle" src="/images/landing/spa-services-image.png" alt="hair services image"/>
                                        </div>
                                        <div className="bg-services-card-container text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">Spa</label>
                                            <div className="pt-md-2 pt-sm-1">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,
                                                    we take pride in creating styles for any occasion!
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={"/service-details"}>
                                                    <button type="button" className="button-see-details font-family-raleway">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-none col-md-4 mt-4">
                                    <div className="service-container card bg-transparent border-0">
                                        <div className="img-container">
                                            <img className="img-fluid w-100 rounded-top-circle" src="/images/landing/nails-services-image.png" alt="nail services image"/>
                                        </div>
                                        <div className="bg-services-card-container text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">Nail</label>
                                            <div className="pt-md-2 pt-sm-1">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,
                                                    we take pride in creating styles for any occasion!
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={"/service-details"}>
                                                    <button type="button" className="button-see-details font-family-raleway">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center px-4 d-none">
                                <div className="col-12 col-md-4 mt-4">
                                    <div className="service-container card bg-transparent border-0">
                                        <div className="img-container">
                                            <img className="img-fluid w-100 rounded-top-circle" src="/images/services/services-make-up-image.png" alt="makeup services image"/>
                                        </div>
                                        <div className="bg-services-card-container text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">Make Up</label>
                                            <div className="pt-md-2 pt-sm-1">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,
                                                    we take pride in creating styles for any occasion!
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={"/service-details"}>
                                                    <button type="button" className="button-see-details font-family-raleway">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mt-4">
                                    <div className="service-container card bg-transparent border-0">
                                        <div className="img-container">
                                            <img className="img-fluid w-100 rounded-top-circle" src="/images/services/services-skin-care-applying-image.png" alt="skin care services image"/>
                                        </div>
                                        <div className="bg-services-card-container text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">Skin Care</label>
                                            <div className="pt-md-2 pt-sm-1">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,
                                                    we take pride in creating styles for any occasion!
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={"/service-details"}>
                                                    <button type="button" className="button-see-details font-family-raleway">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mt-4">
                                    <div className="service-container card bg-transparent border-0">
                                        <div className="img-container">
                                            <img className="img-fluid w-100 rounded-top-circle" src="/images/services/services-waxing-image.png" alt="wax services image"/>
                                        </div>
                                        <div className="bg-services-card-container text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">Waxing</label>
                                            <div className="pt-md-2 pt-sm-1">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,
                                                    we take pride in creating styles for any occasion!
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={"/service-details"}>
                                                    <button type="button" className="button-see-details font-family-raleway">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*book an appointment*/}
            <div className="row bg-color-container bg-appointment-book-container pt-4">
                <div className="col-12 col-md-11 mt-4">
                    <div className="row d-flex justify-content-between ">
                        <div className="col-12 col-md-2 d-none d-md-block">
                            <img className="img-fluid w-100" src="/images/landing/shaving-image.png" alt="men shaving-image"/>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <div className="text-center">
                                <label className="font-size-42 font-weight-500p font-color-theme-950">Don't Wait, Book Your Appointment Online and Skip the Line</label>
                            </div>
                            <div className="py-4 text-center">
                                <Link to="/book-appointment.html">
                                    <button type="button" className="button-bg text-white font-size-18 font-weight-500p font-family-raleway">Book an appointment</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-2 d-none d-md-flex flex-column justify-content-end">
                            <img className="img-fluid w-100" src="/images/landing/hair-strain-image.png" alt="hair strain image"/>
                        </div>
                    </div>
                </div>
            </div>


            {/*footer section*/}
            <FooterPage />
        </div>
    )
}

export default AllServices
