import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";

import eachServicesData from '../Models/eachServices.json';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const ServiceDetails = () => {

    const params = useParams();
    const id = params.id;

    const [service, setService] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
        const data = eachServicesData.find(each => each.service_slug === id);
        setService(data ? [data] : []);
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return(
        <div className="container-fluid">

            <div className="row">
                <div className="col-12">
                    {/*nav bar*/}
                    <div className="row justify-content-center pb-4">
                        <NavbarPage />
                        <div className="col-12 p-0 ">
                            {/*<nav className="row navbar navbar-danger bg-color-navbar d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="col-4 font-color-white d-flex justify-content-center align-items-center d-block d-md-none">*/}
                            {/*        <a href="/index.html">*/}
                            {/*            <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo">*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6 d-flex flex-row justify-content-between align-content-center d-none d-md-flex px-4 ">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-3">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo">*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-7 d-flex flex-row justify-content-start align-items-center px-4 gap-4">*/}
                            {/*                <div className="nav-item dropdown">*/}
                            {/*                    <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                        services*/}
                            {/*                    </a>*/}
                            {/*                    <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdown">*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/about.html" className=" btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/contact-us.html" className="btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        Contact us*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="col-6 d-flex justify-content-end align-items-center d-none d-md-flex">*/}
                            {/*        <a href="/book-appointment.html">*/}
                            {/*            <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                            {/*                Book an appointment*/}
                            {/*            </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2">*/}
                            {/*        <button className="navbar-toggler bg-transparent border-none  d-block d-md-none" type="button"*/}
                            {/*                data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent"*/}
                            {/*                aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                aria-label="Toggle navigation">*/}
                            {/*            <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</nav>*/}
                            {/*<div className="collapse mt-4" id="navbarToggleExternalContent">*/}
                            {/*    <div className="bg-color-navbar p-4 d-flex justify-content-between align-content-start gap-2 flex-column">*/}
                            {/*        <div className="">*/}
                            {/*            <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="/all-services-details.html" id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                services*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu bg-color-navbar font-family-raleway" aria-labelledby="navbarDropdownMobile">*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="">*/}
                            {/*            <a href="/about.html" className=" btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                About*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="">*/}
                            {/*            <a href="/contact-us.html" className="btn font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                Contact us*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className=" border-2">*/}
                            {/*            <a href="/book-appointment.html">*/}
                            {/*                <button type="button" className="button-bg font-family-raleway text-white font-size-14 font-weight-500p border-none">*/}
                            {/*                    Book an appointment*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                    {/*hero section*/}

                    {service.map(each => (
                        <div className="row bg-services-hero-section" key={each.id} style={{backgroundImage: `linear-gradient(to right, #00000080 0%, #00000080 20%), url(${each.service_background_image})`}}>
                            <div className="col-12" >
                                {/*hero section*/}
                                <div className="row justify-content-center">
                                    <div className="col-12 py-4 my-4"></div>
                                    <div className="col-12 col-md-4">
                                        <div className="text-center">
                                            <label className="font-size-42 font-color-theme-300 font-weight-500p">{each.service_name}</label>
                                        </div>
                                        <div className="text-center pt-2">
                                            <label className="font-color-white font-size-14 font-family-raleway">{each.service_description}</label>
                                        </div>
                                        <div className="text-center pt-4">
                                            <Link to={"/book-an-appointment"}>
                                                <button type="button" className="button-bg font-size-18 font-weight-500p font-family-raleway text-white">Book an Appointment</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 d-none col-md-4">
                                        <div className="text-center">
                                            <label className="font-size-42 font-color-theme-300 font-weight-500p">Hair Styling</label>
                                        </div>
                                        <div className="text-center pt-2">
                                            <label className="font-color-white font-size-14 font-family-raleway">The perfect look for any occasion, a simple blow, a soeciak occasion or a creative braided style.</label>
                                        </div>
                                        <div className="text-center pt-4">
                                            <Link to={"/book-an-appointment"}>
                                                <button type="button" className="button-bg font-size-18 font-weight-500p font-family-raleway text-white">Book an Appointment</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 py-4"></div>
                                    <div className="col-12 py-4"></div>
                                    <div className="col-12 py-4 d-none d-md-block"></div>
                                    <div className="col-12 py-4 d-none d-md-block"></div>
                                </div>
                            </div>
                        </div>
                    ))}



                    {/*hair image container*/}
                    <div className="row bg-color-container  justify-content-center ">
                        {service.map(serviceImage => (
                            <div className="col-12 col-md-10 bg-hair-image-container" key={serviceImage.id}>
                                <img className="img-fluid w-100 br-15px" src={serviceImage.service_display_image} alt={serviceImage.alt}/>
                            </div>
                        ))}
                    </div>


                    {/*what we include in services*/}
                    <div className="row bg-color-container py-2">
                        <div className="col-12">
                            <div className="row py-4 justify-content-center">
                                <div className="col-12 col-md-4 ps-4">
                                    <label className="font-size-42 font-color-theme-500 font-weight-500p">What We Include In The Service</label>
                                </div>
                                <div className="col-12 col-md-7 font-family-raleway">
                                    <ul>
                                        {service.map(serviceProvide => (
                                           serviceProvide.include_in_services.map((each,index) => (
                                               <li key={index} className="font-size-18 font-weight-400p font-color-grey-dark mb-2">{each}</li>
                                           ))
                                        ))}
                                        {/*<li className="font-size-18 font-weight-400p font-color-grey-dark pb-2">A personalized consultation to understand your hair goals and suggest the best styling option</li>*/}
                                        {/*<li className="font-size-18 font-weight-400p font-color-grey-dark pb-2">A customized styling service that suits your hair type and face shape</li>*/}
                                        {/*<li className="font-size-18 font-weight-400p font-color-grey-dark pb-2">A relaxing shampoo and conditioning treatment to prepare your hair for styling</li>*/}
                                        {/*<li className="font-size-18 font-weight-400p font-color-grey-dark pb-2">A blow-dry and finishing style to complete your look</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*sub services container*/}
                    <div className="row bg-color-container py-4">
                        <div className="col-12">
                            <div className="row justify-content-evenly px-4">
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label className="font-size-42 font-color-theme-500 font-weight-500p">Sub-Services</label>
                                    </div>
                                    {service.map(subServices => (
                                        subServices.sub_services.map((eachSub, index) => (
                                            <div className="mt-4 pb-4 font-family-raleway sub-service-border-bottom" key={index}>
                                                <div>
                                                    <label className="font-size-18 font-color-grey-dark font-weight-500p">{eachSub.sub_service_name}</label>
                                                </div>
                                                <div>
                                                    <label className="font-size-14 font-color-grey-medium font-weight-500p">{eachSub.sub_service_description}</label>
                                                </div>
                                            </div>
                                        ))
                                    ))}
                                    <div>
                                        {/*<div className="pt-4 font-family-raleway">*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-18 font-color-grey-dark font-weight-500p">HAIR CUT</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-14 font-color-grey-medium font-weight-500p">Including Washing Drying</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="pt-4 font-family-raleway">*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-18 font-color-grey-dark font-weight-500p">HAIR COLORING</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-14 font-color-grey-medium font-weight-500p">Includes Haircut, Washing, Coloring</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="pt-4 font-family-raleway">*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-18 font-color-grey-dark font-weight-500p">HAIR STRAIGHTENING</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-14 font-color-grey-medium font-weight-500p">Includes Haircut, Washing, Coloring</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="pt-4 font-family-raleway">*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-18 font-color-grey-dark font-weight-500p">BLOW DRY & CURL</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <label className="font-size-14 font-color-grey-medium font-weight-500p">Includes Haircut, Washing, Coloring</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="row d-none d-md-flex justify-content-between" >
                                        {service.map(subServiceImage => (
                                            subServiceImage.sub_service_images.map((eachSubImage, index) => (
                                                <div className="col-12 col-md-4 w-50" key={index}>
                                                    <img className="img-fluid br-top-left-right-500px" src={eachSubImage.image_url} alt={eachSubImage.alt}/>
                                                </div>
                                            ))
                                        ))}
                                        {/*<div className="col-12 col-md-4 w-50">*/}
                                        {/*    <img className="img-fluid" src="/images/services/lady-image-with-pink-hair.png" alt="lady pink hair"/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Book appointment*/}
                    <div className="row bg-color-container bg-appointment-book-container pt-4">
                        <div className="col-12 col-md-11">
                            <div className="row d-flex justify-content-between ">
                                <div className="col-12 col-md-2 d-none d-md-block">
                                    <img className="img-fluid w-100" src="/images/landing/shaving-image.png" alt="men shaving-image"/>
                                </div>
                                <div className="col-12 col-md-6 p-2">
                                    <div className="text-center">
                                        <label className="font-size-42 font-weight-500p font-color-theme-950">Don't Wait, Book Your Appointment Online and Skip the Line</label>
                                    </div>
                                    <div className="py-4 text-center">
                                        <Link to={"/book-an-appointment"}>
                                            <button type="button" className="button-bg text-white font-size-18 font-weight-500p font-family-raleway">Book an appointment</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 d-none d-md-flex flex-column justify-content-end">
                                    <img className="img-fluid w-100" src="/images/landing/hair-strain-image.png" alt="hair strain image"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Gallery container*/}
                    <div className="row bg-color-container p-4">
                        {service.map(serviceGallery => (
                            <div className="col-12 gap-4 mt-4 py-2" key={serviceGallery.id}>
                                <div className="row py-2 ">
                                    <div className="col-12 text-center">
                                        <div>
                                            <label className="font-size-42 font-weight-500p font-color-theme-500">Gallery</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-4 px-2" >
                                    <div className="col-4 col-md-3">
                                        <img className="img-fluid br-top-left-500px" src={serviceGallery.service_gallery[0].image_url} alt={serviceGallery.service_gallery[0].alt}/>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <img className="img-fluid" src={serviceGallery.service_gallery[1].image_url} alt={serviceGallery.service_gallery[1].alt}/>
                                    </div>
                                    <div className="col-12 d-none d-md-block col-md-3">
                                        <img className="img-fluid" src={serviceGallery.service_gallery[2].image_url} alt={serviceGallery.service_gallery[2].alt}/>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <img className="img-fluid br-top-right-500px" src={serviceGallery.service_gallery[3].image_url} alt={serviceGallery.service_gallery[3].alt}/>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-4 px-2">
                                    <div className="col-4 col-md-3">
                                        <img className="img-fluid br-bottom-left-500px" src={serviceGallery.service_gallery[4].image_url} alt={serviceGallery.service_gallery[4].alt}/>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <img className="img-fluid" src={serviceGallery.service_gallery[5].image_url} alt={serviceGallery.service_gallery[5].alt}/>
                                    </div>
                                    <div className="col-12 d-none d-md-block col-md-3">
                                        <img className="img-fluid" src={serviceGallery.service_gallery[6].image_url} alt={serviceGallery.service_gallery[6].alt}/>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <img className="img-fluid br-bottom-right-500px" src={serviceGallery.service_gallery[7].image_url} alt={serviceGallery.service_gallery[7].alt}/>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>


                    {/*footer section*/}
                    <FooterPage />
                </div>
            </div>
        </div>
    )
}

export default ServiceDetails
