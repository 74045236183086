import React, {useEffect, useState} from "react";

import NavbarPage from "../NabarPage/navbarPage";
import FooterPage from "../FooterPage/footerPage";
import {Link} from "react-router-dom";
import * as Icon from 'react-feather';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css'
import servicesData from '../Models/services.json';

const LandingPage = () => {

    const [servicesSwiper, setServicesSwiper] = useState(null);
    const [reviewSwiper, setReviewSwiper] = useState(null);

    const goServicesNext = () => {
        if (servicesSwiper !== null) {
            servicesSwiper.slideNext();
        }
    }

    const goSwiperPrev = () => {
        if (servicesSwiper !== null) {
            servicesSwiper.slidePrev();
        }
    }

    const goReviewNext = () => {
        if (reviewSwiper !== null) {
            reviewSwiper.slideNext();
        }
    }

    const goReviewPrev = () => {
        if (reviewSwiper !== null) {
            reviewSwiper.slidePrev();
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return(
        <div className="container-fluid">
            {/*hero section*/}

            <div className="row bg-color-container">
                <div className="col-12">
                    {/*nav bar*/}
                    <div className="row justify-content-center fixed-top">
                        <NavbarPage />
                        <div className="col-12 p-md-0">
                            {/*<nav className="row navbar navbar-danger bg-color-navbar d-flex justify-content-between align-items-center px-4">*/}
                            {/*    <div className="col-4 font-color-white d-flex justify-content-center align-items-center d-block d-md-none">*/}
                            {/*        <a href="/index.html">*/}
                            {/*            <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6 d-flex flex-row justify-content-between align-content-center d-none d-md-flex px-4 ">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-3">*/}
                            {/*                <a href="/index.html">*/}
                            {/*                    <img className="img-fluid bg-transparent" src="/images/landing/salon-logo.png" alt="salon logo"/>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-7 d-flex flex-row justify-content-start align-items-center px-4 gap-4">*/}
                            {/*                <div className="nav-item dropdown">*/}
                            {/*                    <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                        services*/}
                            {/*                    </a>*/}
                            {/*                    <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdown">*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                        <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/about.html" className=" btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        About*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*                <div>*/}
                            {/*                    <a href="/contact-us.html" className="btn bg-transparent font-family-raleway font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                        Contact us*/}
                            {/*                    </a>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="col-6 d-flex justify-content-end align-items-center d-none d-md-flex">*/}
                            {/*        <a href="/book-appointment.html">*/}
                            {/*            <button type="button" className="button-bg text-white font-size-14 font-weight-500p font-family-raleway border-none">*/}
                            {/*                Book an appointment*/}
                            {/*            </button>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2">*/}
                            {/*        <button className="navbar-toggler bg-transparent border-none  d-block d-md-none" type="button"*/}
                            {/*                data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent"*/}
                            {/*                aria-controls="navbarToggleExternalContent" aria-expanded="false"*/}
                            {/*                aria-label="Toggle navigation">*/}
                            {/*            <span className="navbar-toggler-icon text-indgo"></span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</nav>*/}
                            {/*<div className="collapse" id="navbarToggleExternalContent">*/}
                            {/*    <div className="bg-color-navbar p-4 d-flex flex-column justify-content-between align-content-start gap-2 ">*/}

                            {/*        <div className="">*/}
                            {/*            <a className="nav-link dropdown-toggle font-size-14 font-family-raleway font-weight-500p font-color-grey-dark" href="#" id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                services*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu font-family-raleway bg-color-navbar" aria-labelledby="navbarDropdownMobile">*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Hair Style</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Spa</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Nail</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Make Up</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Skin Care</a>*/}
                            {/*                <a className="dropdown-item font-size-14" href="/all-services-details.html">Waxing</a>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*        <div className="">*/}
                            {/*            <a href="/about.html" className=" font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                <label> About</label>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className="">*/}
                            {/*            <a href="/contact-us.html" className=" font-family-raleway bg-transparent font-color-grey-dark font-size-14 font-weight-500p border-none">*/}
                            {/*                <label>Contact us</label>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*        <div className=" border-2">*/}
                            {/*            <a href="/book-appointment.html">*/}
                            {/*                <button type="button" className="button-bg font-family-raleway text-white font-size-14 font-weight-500p border-none">*/}
                            {/*                    Book an appointment*/}
                            {/*                </button>*/}
                            {/*            </a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/*hero section*/}
                    <div className="row">
                        <div className="col-12 py-3"></div>
                    </div>
                    <div className="row bg-hero-section-container pt-4 mt-4">
                        <div className="col-12 pt-4 mt-4">
                            <div className="row pb-4 d-flex flex-row justify-content-center align-items-center">
                                <div className="col-12 d-block d-md-none">
                                    <div className="row">
                                        <div className="col-12">
                                            <img className="img-fluid w-100 " src="/images/landing/hair-wash-hero-section-image.png" alt="hair wash"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 order-md-0 order-1 ord or d-flex flex-column  justify-content-center">
                                    <div className="row">
                                        <div className="col-12 d-none d-md-block">
                                            <img className="img-fluid w-100 " src="/images/landing/hair-wash-hero-section-image.png" alt="hair wash"/>
                                        </div>
                                    </div>
                                    <div className="d-none d-md-block pt-4 mt-2"></div>
                                    <div className="mt-4 d-flex flex-column gap-2">
                                        <div className="hero-section-details-container d-flex justify-content-between align-items-center">
                                            <div>
                                                <label className="font-size-48 font-weight-500p">32</label>
                                            </div>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-family-raleway">Awards Achieved</label>
                                            </div>
                                        </div>
                                        <div className="hero-section-details-container d-flex justify-content-between align-items-center ">
                                            <div>
                                                <label className="font-size-48 font-weight-500p">5+</label>
                                            </div>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-family-raleway">Active years</label>
                                            </div>
                                        </div>
                                        <div className="hero-section-details-container d-flex justify-content-between align-items-center ">
                                            <div>
                                                <label className="font-size-48 font-weight-500p">08</label>
                                            </div>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-family-raleway">Number Of Branches</label>
                                            </div>
                                        </div>
                                        <div className="hero-section-details-container text-wrap d-flex justify-content-between align-items-center ">
                                            <div>
                                                <label className="font-size-48 font-weight-500p">15K+</label>
                                            </div>
                                            <div>
                                                <label className="font-size-14 font-weight-500p font-family-raleway">Happy customers</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pt-4 d-block d-md-none"></div>

                                <div className="col-12 d-flex flex-column justify-content-start col-md-8 pb-4 px-4">
                                    <label className="font-color-gold font-size-88 font-weight-600p font-align-center">Unleash Your Inner Beauty at Our Salon</label>
                                    <label className="font-color-grey-dark font-size-18 font-weight-500p font-family-raleway font-align-center">Whether it’s hairstyle, massage spa, makeup, or nail art, leave it to us and relax.</label>
                                    <div className="mt-4 font-align-center">
                                        <Link to={"/book-an-appointment"}>
                                            <button type="button" className="button-bg text-white font-size-18 font-weight-500p font-family-raleway">Book an appointment</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*services we offer*/}

            <div className="row bg-color-container pt-4 pb-4">
                <div className="col-12 pb-4 mt-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            <label className="font-color-gold font-size-42 font-weight-500p">Services We Offer</label>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-2 col-md-1 d-flex flex-column justify-content-center align-items-center">
                            <button type="button" onClick={goSwiperPrev} className="button-services-left-right-arrow rounded-circle d-flex align-items-center p-3" id="prev-button">
                                <Icon.ChevronLeft className="font-color-gold"/>
                            </button>
                        </div>

                        <Swiper
                            className="col-8 col-md-10 services-swiper"
                            slidesPerView={3}
                            loop={true}
                            spaceBetween={10}
                            breakpoints={{
                                300 : {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },

                                768 : {
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                }
                            }}
                            onSwiper={setServicesSwiper}
                        >
                            {servicesData.map(each => (
                                <SwiperSlide key={each.id} className={""}>
                                    <div className="card h-100 bg-transparent border-0 mt-4">
                                        <div className="img-container flex-shrink-0">
                                            <img className="img-fluid w-100 rounded-top-circle" src={each.service_image} alt={each.alt}/>
                                        </div>
                                        <div className="bg-services-card-container flex-grow-1 text-center p-4">
                                            <label className="font-size-32 font-color-brown font-weight-500p">{each.service_name}</label>
                                            <div className="pt-2">
                                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">
                                                    {each.service_description}
                                                </label>
                                            </div>
                                            <div className="pt-4">
                                                <Link to={`/service-details/${each.service_slug}`}>
                                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">
                                                        See details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <div className={"d-none"}>
                            {/*<div className="splide__track d-none">*/}
                            {/*    <ul className="splide__list">*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <div className=" card bg-transparent border-0 mt-4">*/}
                            {/*                        <div className="img-container">*/}
                            {/*                            <img className="img-fluid w-100 rounded-top-circle" src="/images/landing/hair-services-image.png" alt="hair services image"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="bg-services-card-container text-center p-4">*/}
                            {/*                            <label className="font-size-32 font-color-brown font-weight-500p">Hair Service</label>*/}
                            {/*                            <div className="pt-2">*/}
                            {/*                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,*/}
                            {/*                                    we take pride in creating styles for any occasion!*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="pt-4">*/}
                            {/*                                <Link to={"/service-details"}>*/}
                            {/*                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">*/}
                            {/*                                        See details*/}
                            {/*                                    </button>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <div className=" card bg-transparent border-0 mt-4">*/}
                            {/*                        <div className="img-container">*/}
                            {/*                            <img className="img-fluid w-100 rounded-top-circle" src="/images/landing/spa-services-image.png" alt="spa services image"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="bg-services-card-container text-center p-4">*/}
                            {/*                            <label className="font-size-32 font-color-brown font-weight-500p">Spa</label>*/}
                            {/*                            <div className="pt-2">*/}
                            {/*                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,*/}
                            {/*                                    we take pride in creating styles for any occasion!*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="pt-4">*/}
                            {/*                                <Link to={"/service-details"}>*/}
                            {/*                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">*/}
                            {/*                                        See details*/}
                            {/*                                    </button>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <div className=" card bg-transparent border-0 mt-4">*/}
                            {/*                        <div className="img-container">*/}
                            {/*                            <img className="img-fluid w-100 rounded-top-circle" src="/images/landing/nails-services-image.png" alt="nail services image"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="bg-services-card-container text-center p-4">*/}
                            {/*                            <label className="font-size-32 font-color-brown font-weight-500p">Nail</label>*/}
                            {/*                            <div className="pt-2">*/}
                            {/*                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,*/}
                            {/*                                    we take pride in creating styles for any occasion!*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="pt-4">*/}
                            {/*                                <Link to={"/service-details"}>*/}
                            {/*                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">*/}
                            {/*                                        See details*/}
                            {/*                                    </button>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <div className=" card bg-transparent border-0 mt-4">*/}
                            {/*                        <div className="img-container">*/}
                            {/*                            <img className="img-fluid w-100 rounded-top-circle" src="/images/services/services-make-up-image.png" alt="makeup services image"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="bg-services-card-container text-center p-4">*/}
                            {/*                            <label className="font-size-32 font-color-brown font-weight-500p">Make Up</label>*/}
                            {/*                            <div className="pt-2">*/}
                            {/*                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,*/}
                            {/*                                    we take pride in creating styles for any occasion!*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="pt-4">*/}
                            {/*                                <Link to={"/service-details"}>*/}
                            {/*                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">*/}
                            {/*                                        See details*/}
                            {/*                                    </button>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <div className=" card bg-transparent border-0 mt-4">*/}
                            {/*                        <div className="img-container">*/}
                            {/*                            <img className="img-fluid w-100 rounded-top-circle" src="/images/services/services-skin-care-applying-image.png" alt="skin care services image"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="bg-services-card-container text-center p-4">*/}
                            {/*                            <label className="font-size-32 font-color-brown font-weight-500p">Skin care</label>*/}
                            {/*                            <div className="pt-2">*/}
                            {/*                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,*/}
                            {/*                                    we take pride in creating styles for any occasion!*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="pt-4">*/}
                            {/*                                <Link to={"/service-details"}>*/}
                            {/*                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">*/}
                            {/*                                        See details*/}
                            {/*                                    </button>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-12">*/}
                            {/*                    <div className=" card h-100 bg-transparent border-0 mt-4">*/}
                            {/*                        <div className="img-container">*/}
                            {/*                            <img className="img-fluid w-100 rounded-top-circle" src="/images/services/services-waxing-image.png" alt="waxing services image"/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="bg-services-card-container text-center p-4">*/}
                            {/*                            <label className="font-size-32 font-color-brown font-weight-500p">Waxing</label>*/}
                            {/*                            <div className="pt-2">*/}
                            {/*                                <label className="font-family-raleway font-size-14 font-weight-400p font-color-black-opacity">Your hair is a reflection of you, and at Bubbles,*/}
                            {/*                                    we take pride in creating styles for any occasion!*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="pt-4">*/}
                            {/*                                <Link to={"/service-details"}>*/}
                            {/*                                    <button type="button" className="button-see-details font-family-raleway font-size-14 font-weight-500p font-color-grey-dark">*/}
                            {/*                                        See details*/}
                            {/*                                    </button>*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-2 col-md-1 d-flex flex-column justify-content-center align-items-center">
                            <button type="button" onClick={goServicesNext} className="button-services-left-right-arrow rounded-circle d-flex align-items-center p-3" id="next-button">
                                <Icon.ChevronRight className="font-color-gold"/>
                            </button>
                        </div>

                    </div>

                    <div className="button-container text-center mt-4 pt-4">
                        <Link to={"/all-services"}>
                            <button type="button" className="button-bg font-family-raleway button-explore-all text-white font-size-18 font-weight-500p">
                                Explore all services
                                <span> <Icon.ArrowRight /></span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>


            {/*our customer reviews*/}

            <div className="row bg-customer-review-container justify-content-center pb-4">
                <div className="col-12 col-md-10 justify-content-center py-4">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-5 text-center">
                            <label className="font-size-42 font-weight-500p font-color-theme-800">Our Customers' Stories And Reviews</label>
                        </div>
                    </div>

                    <div className="row justify-content-center pt-4">
                        <div className="col-2 col-md-1 d-flex flex-column justify-content-center align-items-center">
                            <button type="button" onClick={goReviewPrev} className="button-reviews-left-right-arrow rounded-circle p-3" id="review-prev-button">
                                <Icon.ChevronLeft className="font-color-light-pallete"/>
                            </button>
                        </div>

                        <Swiper
                            className={"col-8 col-md-10"}
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            onSwiper={setReviewSwiper}
                        >
                            <SwiperSlide>
                                <div className="row justify-content-center h-100">
                                    <div className="col-10 p-4 bg-reviews-container gap-4">
                                        <div className="text-center p-4">
                                            <label className="font-family-raleway font-size-18 font-weight-400p">
                                                I am so glad I found ’Suits Salon’. They do an outstanding job on my hair. Each time is such a treat with the wonderful head massage. I always feel so pampered!
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <label className="font-color-theme-950 font-size-32 font-weight-400p">Lipsa</label>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row justify-content-center h-100">
                                    <div className="col-10 p-4 bg-reviews-container gap-4">
                                        <div className="text-center p-4">
                                            <label className="font-family-raleway font-size-18 font-weight-400p">
                                                I moved to Hyderabad from Chennai and was having a tough time finding a hair professional that I could trust. Then I found this ‘Suite Salon’ and decided to make an appointment. Now I realise I made the right decision that day. I am extremely happy I found ‘Suits’ and my hair has never felt so healthy.
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <label className="font-color-theme-950 font-size-32 font-weight-400p">Naveen Kumar</label>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row justify-content-center h-100">
                                    <div className="col-10 p-4 bg-reviews-container gap-4">
                                        <div className="text-center p-4">
                                            <label className="font-family-raleway font-size-18 font-weight-400p">
                                                The team at Salon Suits is amazing! From their personalities to their level of service, they have become my go to place for facials and spa. This salon team has provided a great experience that cannot be matched! She has given great styling tips and shown me products that have improved my skin health. You Rock! Thank you Salon Suits.
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <label className="font-color-theme-950 font-size-32 font-weight-400p">Rashmika</label>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row justify-content-center h-100">
                                    <div className="col-10 p-4 bg-reviews-container gap-4">
                                        <div className="text-center p-4">
                                            <label className="font-family-raleway font-size-18 font-weight-400p">
                                                I contacted ‘SUITS’  to do my bridal makeup. Their skills are unmatched. I was looking gorgeous at my wedding. Wedding makeup is an important decision and you can definitely trust SUITS for the same.
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <label className="font-color-theme-950 font-size-32 font-weight-400p">Riya</label>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="row justify-content-center h-100">
                                    <div className="col-10 p-4 bg-reviews-container gap-4">
                                        <div className="text-center p-4">
                                            <label className="font-family-raleway font-size-18 font-weight-400p">
                                                I got the best mani-pedi at SUITS and also got beautiful nail art that everyone admires. The staff is friendly, warm, and cooperative. They greet me with happy faces all the time. Love this place and its atmosphere.  I’m surely visiting ‘SUITS’  again.
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <label className="font-color-theme-950 font-size-32 font-weight-400p">Monalisa</label>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                        <div className="splide col-8 col-md-10 d-none" role="group" aria-label="Unimportant Content" id="review-splide">
                            <div className="splide__track">
                                <ul className="splide__list">
                                    <li className="splide__slide">
                                        <div className="row">
                                            <div className="col-12 p-4 bg-reviews-container gap-4">
                                                <div className="text-center p-4">
                                                    <label className="font-family-raleway font-size-18 font-weight-400p">I love Pix! They always make me feel welcome and pampered. The salon is clean, cozy, and stylish.
                                                        The stylists are amazing and listen to what I want. They transformed my hair into a beautiful masterpiece.
                                                        Pix is the only salon I trust with my hair.
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <label className="font-color-theme-950 font-size-32 font-weight-400p">Naveen Kumar</label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="splide__slide">
                                        <div className="row">
                                            <div className="col-12 p-4 bg-reviews-container gap-4">
                                                <div className="text-center p-4">
                                                    <label className="font-family-raleway font-size-18 font-weight-400p">I love Pix! They always make me feel welcome and pampered. The salon is clean, cozy, and stylish.
                                                        The stylists are amazing and listen to what I want. They transformed my hair into a beautiful masterpiece.
                                                        Pix is the only salon I trust with my hair.
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <label className="font-color-theme-950 font-size-32 font-weight-400p">Naveen Kumar</label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="splide__slide">
                                        <div className="row">
                                            <div className="col-12 p-4 bg-reviews-container gap-4">
                                                <div className="text-center p-4">
                                                    <label className="font-family-raleway font-size-18 font-weight-400p">I love Pix! They always make me feel welcome and pampered. The salon is clean, cozy, and stylish.
                                                        The stylists are amazing and listen to what I want. They transformed my hair into a beautiful masterpiece.
                                                        Pix is the only salon I trust with my hair.
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <label className="font-color-theme-950 font-size-32 font-weight-400p">Naveen Kumar</label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="splide__slide">
                                        <div className="row">
                                            <div className="col-12 p-4 bg-reviews-container gap-4">
                                                <div className="text-center p-4">
                                                    <label className="font-family-raleway font-size-18 font-weight-400p">I love Pix! They always make me feel welcome and pampered. The salon is clean, cozy, and stylish.
                                                        The stylists are amazing and listen to what I want. They transformed my hair into a beautiful masterpiece.
                                                        Pix is the only salon I trust with my hair.
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <label className="font-color-theme-950 font-size-32 font-weight-400p">Naveen Kumar</label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-2 col-md-1 d-flex flex-column justify-content-center align-items-center">
                            <button type="button" onClick={goReviewNext} className="button-reviews-left-right-arrow p-3 rounded-circle" id="review-next-button">
                                <Icon.ChevronRight className="font-color-light-pallete"/>
                            </button>
                        </div>

                    </div>

                    <div className="row justify-content-center pb-4">
                        {/*                <div className="col-12 col-md-10">*/}

                        {/*                    <div className="row justify-content-center pt-4">*/}
                        {/*                        <div className="col-12 col-md-1 d-none d-md-flex flex-column justify-content-center align-items-center">*/}
                        {/*                            <button type="button" className="button-reviews-left-right-arrow px-3 py-2 text-center">*/}
                        {/*                                <img className="img-fluid" src="/images/landing/reivews-left-arrow-image.png" alt="left arrow">*/}
                        {/*                            </button>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="col-10 col-md-8 p-4 bg-reviews-container gap-4">*/}
                        {/*                            <div className="text-center p-4">*/}
                        {/*                                <label className="font-family-raleway font-size-18 font-weight-400p">I love Pix! They always make me feel welcome and pampered. The salon is clean, cozy, and stylish.*/}
                        {/*                                    The stylists are amazing and listen to what I want. They transformed my hair into a beautiful masterpiece.*/}
                        {/*                                    Pix is the only salon I trust with my hair.*/}
                        {/*                                </label>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="text-center">*/}
                        {/*                                <label className="font-color-theme-950 font-size-32 font-weight-400p">Naveen Kumar</label>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="col-12 col-md-1 d-none d-md-flex flex-column justify-content-center align-items-center">*/}
                        {/*                            <button type="button" className="button-reviews-left-right-arrow px-3 py-2 text-center">*/}
                        {/*                                <img className="img-fluid" src="/images/landing/reviews-right-arrow-image.png" alt="right arrow">*/}
                        {/*                            </button>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="col-12 mt-4 d-flex justify-content-center gap-4 d-md-none">*/}
                        {/*                            <div>*/}
                        {/*                                <button type="button" className="button-reviews-left-right-arrow px-3 py-2 text-center">*/}
                        {/*                                    <img className="img-fluid" src="/images/landing/reivews-left-arrow-image.png" alt="left arrow">*/}
                        {/*                                </button>*/}
                        {/*                            </div>*/}
                        {/*                            <div>*/}
                        {/*                                <button type="button" className="button-reviews-left-right-arrow px-3 py-2 text-center">*/}
                        {/*                                    <img className="img-fluid" src="/images/landing/reviews-right-arrow-image.png" alt="right arrow">*/}
                        {/*                                </button>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                    </div>
                </div>
            </div>

            {/*Book appointment*/}

            <div className="row bg-color-container bg-appointment-book-container pt-4">
                <div className="col-12 col-md-11">
                    <div className="row d-flex justify-content-between ">
                        <div className="col-12 col-md-2 d-none d-md-block">
                            <img className="img-fluid w-100" src="/images/landing/shaving-image.png" alt="men shaving-image"/>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <div className="text-center">
                                <label className="font-size-42 font-weight-500p font-color-theme-950">Don't Wait, Book Your Appointment Online and Skip the Line</label>
                            </div>
                            <div className="py-4 text-center">
                                <Link to={"/book-an-appointment"}>
                                    <button type="button" className="button-bg text-white font-size-18 font-weight-500p font-family-raleway">Book an appointment</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-2 d-none d-md-flex flex-column justify-content-end">
                            <img className="img-fluid w-100" src="/images/landing/hair-strain-image.png" alt="hair strain image"/>
                        </div>
                    </div>
                </div>
            </div>

            {/*footer section*/}

            <FooterPage />
        </div>
    )
}

export default LandingPage
